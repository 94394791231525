import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview del Proyecto`}</h1>
    <p>{`Dado a que `}<em parentName="p">{`AgroBioTek`}</em>{` ofrece multiples servicios y productos, desde análisis de laboratorio hasta capacitaciones para la agroindustria, tomaron la decisión de separar sus servicios de capacitación y ofrecer a los clientes una plataforma exclusiva donde pueden ver el contenido de cada curso, registrarse online y solicitar cotizaciones a través de una plataforma integral.`}</p>
    <h2>{`Gatsby para el desarrollo Front-end`}</h2>
    <p>{`Porque `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`? Gatsby es un framework de JavaScript que ofrece multiples beneficios a los desarrolladores Front-end, optimización de imágenes, carga en línea de estilos CSS criticos, lazy-loading, prefetching o code splitting por hablar de algunos de sus beneficios.`}</p>
    <h2>{`Despliegue continuo con Netlify`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{` es una plataforma que nace para automatizar proyectos webs. Aúna las tareas de integración continua y despliegue de infraestructura web en un solo flujo de ejecución.`}</p>
    <h2>{`Gestion de Datos con Airtable`}</h2>
    <p><a parentName="p" {...{
        "href": "https://airtable.com/"
      }}>{`Airtable`}</a>{` es otra herramienta multifuncional que tiene un excelente sistema de base de datos visual que genera automáticamente una API específica para cada proyecto.`}</p>
    <br />
    <br />
    <center>
  <a href="https://capacitaciones-agrobiotek.netlify.app/" target="_blank" rel="noopener">
    Ver prototipo de la web
  </a>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      