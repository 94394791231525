import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project Overview`}</h1>
    <p>{`Harry Tatto Supply es el proveedor de productos para artistas del tatuaje más grande del pais, cuando me pidio desarrollar su sitio web, hizo mucho énfasis en no querer un sitio que pareciera una plantilla como cualquier otra y darle a sus clientes una verdadera experiencia de compra.`}</p>
    <p>{`Los problema principal de utilizar plantillas prefabricadas de wordpress o drupal para `}<strong parentName="p">{`abaratar el costo del proyecto`}</strong>{` son muchos, carga de página lenta, mala experiencia de usuario, disminución del tráfico orgánico, altos costos de mantenimiento, pero por sobre todo, `}<strong parentName="p">{`la tienda se parece a cualquier otra tienda`}</strong>{`.`}</p>
    <p>{`La buena noticia es que estos problemas se pueden resolver, utilizando la arquitectura de desarrollo JAMstack, una de las soluciones más populares en estos días.`}</p>
    <h2>{`¿Por qué utilizar Jamstack para un sitio de comercio electrónico?`}</h2>
    <p><strong parentName="p">{`Mejores Tasas de Conversión:`}</strong>{` las páginas se cargan en milisegundos. Gatsby encaja a la perfección con la arquitectura de desarrollo JAMstack para hacer que las webs carguen más rápido, mejorando la generación de clientes potenciales.`}</p>
    <p><strong parentName="p">{`Seguridad:`}</strong>{` al no haber la necesidad de una conección directa con ningún servidor, se eliminan las posibildades de ataques y mantenimientos constantes y costosos.`}</p>
    <h1>{`Stack de Desarrollo`}</h1>
    <h2>{`Gatsby`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{` es un framework que reúne muchas caracteristicas geniales. Su rapidez proporciona una experiencia de desarrollado agradable. Algunas de las características que Gatsby proporciona `}<strong parentName="p">{`"out of the box"`}</strong>{` son las siguiente:`}</p>
    <ul>
      <li parentName="ul">{`Server-Side Rendering en tiempos de ejecución con React Hydration.`}</li>
      <li parentName="ul">{`GraphQL Content Mesh`}</li>
      <li parentName="ul">{`Lazy Loading`}</li>
      <li parentName="ul">{`Optimización de Imagenes`}</li>
    </ul>
    <h2>{`Checkout con Shopify Storefront API`}</h2>
    <p>{`Se necesita de un back-end para manejar los aspectos transaccionales del sitio. El `}<a parentName="p" {...{
        "href": "https://shopify.dev/docs/storefront-api"
      }}>{`API del Storefront`}</a>{` de Shopify Plus permite manejar estas transacciones.`}</p>
    <h2>{`Contentful para la gestión de contenidos (CMS)`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.contentful.com/"
      }}>{`Contentful`}</a>{` es una herramienta CMS para producir, administrar y publicar contenidos de forma sencilla en cualquier web. Basada en la nube, permite a cualquier usuario, ya sean desarrolladores o editores de contenido, trabajar unidos para hacer sus tareas más fáciles y ágiles. `}</p>
    <h2>{`Despliegue continuo con Netlify`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{` es una plataforma que nace para automatizar proyectos webs. Aúna las tareas de integración continua y despliegue de infraestructura web en un solo flujo de ejecución.`}</p>
    <br />
    <br />
    <center>
  <a href="https://harrytattoosupply.com/" target="_blank" rel="noopener">IR A LA WEB</a>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      